<template>
  <div class="weighted-playlists-charts">
    <CardToolbar
      v-loading="loading"
      title="Weighted Playlists Evolution : Reach vs. Count"
      :show-toolbar-buttons="false"
      margin-class="margin-bottom-12"
      :fontStyle="true"
      :padding="false"
      :shadow-class="true"
      v-if="
        playlistReachEvolutionSeries.length &&
        playlistCountEvolutionSeries.length &&
        categories.length
      "
    >
      <template v-slot:body>
        <div class="top-chart">
          <LineAndAreaChartSingleAxisV2
            :show-x-axis="false"
            :stacked="true"
            :y-axis-title="''"
            :is-unix-date="false"
            :chart-height="350"
            :chart-type="'area'"
            :chart-series="playlistReachEvolutionSeries"
            :categories="categories"
            :chart-stroke="{
              curve: 'smooth',
              show: true,
              width: 2,
            }"
            :chart-fill="{
              type: 'gradient',
            }"
            :chart-yaxis-min-width="100"
          />
        </div>
        <div class="bottom-chart">
          <LineAndAreaChartSingleAxisV2
            :hide-first-label-value="true"
            :stacked="true"
            :y-axis-title="''"
            :is-unix-date="false"
            :chart-height="350"
            :chart-type="'area'"
            :chart-series="playlistCountEvolutionSeries"
            :categories="categories"
            :chart-stroke="{
              curve: 'smooth',
              show: true,
              width: 2,
            }"
            :chart-fill="{
              type: 'gradient',
            }"
            :show-negative-tooltip-to-positive="true"
            :chart-yaxis-min-width="100"
          />
        </div>
      </template>
    </CardToolbar>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { getWishlistWeightedPlaylistEvolution } from "@/api/mongoWishlists.api";
import { useRoute } from "vue-router";
import CardToolbar from "@/common/components/CardToolbar.vue";
import LineAndAreaChartSingleAxisV2 from "@/common/components/LineAndAreaChartSingleAxisV2.vue";

export default {
  name: "WeightedPlaylistsEvolution",
  components: { CardToolbar, LineAndAreaChartSingleAxisV2 },
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const playlistReachEvolutionSeries = ref([]);
    const playlistCountEvolutionSeries = ref([]);
    const categories = ref([]);

    onMounted(() => {
      getWeightedPlaylistEvolutionData();
    });

    const getWeightedPlaylistEvolutionData = async () => {
      try {
        loading.value = true;
        const { data } = await getWishlistWeightedPlaylistEvolution(
          route.params.wishlistID
        );
        playlistReachEvolutionSeries.value = data.playlistReachEvolution
          ? data.playlistReachEvolution
          : [];
        for (const datum of data.playlistCountEvolution) {
          playlistCountEvolutionSeries.value.push({
            name: datum.name,
            data: datum.data.map((obj) => -1 * obj),
          });
        }

        categories.value = data.categories ? data.categories : [];
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      playlistReachEvolutionSeries,
      playlistCountEvolutionSeries,
      categories,
    };
  },
};
</script>
<style lang="scss" scoped>
.bottom-chart {
  margin-top: -67px;
}
</style>
