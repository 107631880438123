<script>
import { ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";
import moment from "moment";

export default {
  name: "LineAndAreaChart",
  props: {
    hideFirstLabelValue: {
      type: Boolean,
      default: false,
    },
    isUnixDate: {
      type: Boolean,
      default: false,
    },
    chartHeight: {
      type: Number,
      default: 350,
    },
    chartType: {
      type: String,
      default: "area",
    },
    formatNumber: {
      type: Boolean,
      default: true,
    },
    showYAxisTitle: {
      type: Boolean,
      default: true,
    },
    showXAxis: {
      type: Boolean,
      default: true,
    },
    yAxisTitle: {
      type: String,
      default: "",
    },
    xAxisDate: {
      type: Boolean,
      default: true,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    tooltipXFormat: {
      type: String,
      default: "MMM YY",
    },
    dateFormat: {
      type: String,
      default: "MMM YY",
    },
    fraction: {
      type: Number,
      default: 1,
    },
    chartSeries: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    chartStroke: {
      type: Object,
      default: () => {
        return {
          curve: "smooth",
          show: true,
          width: [3, 3],
          dashArray: [0, 0],
        };
      },
    },
    chartFill: {
      type: Object,
      default: () => {
        return {
          type: "gradient",
          gradient: {
            shadeIntensity: 0,
            inverseColors: true,
          },
          opacity: 1,
        };
      },
    },
    showNegativeTooltipToPositive: {
      type: Boolean,
      default: false,
    },
    chartYaxisMinWidth: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const fontSize = ref("12px");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const options = ref({
      chart: {
        height: props.chartHeight,
        fontFamily: "inherit",
        stacked: props.stacked,
        type: props.chartType,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
        position: "bottom",
        fontSize: fontSize.value,
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      fill: props.chartFill,
      stroke: props.chartStroke,
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeWidth: 3,
      },
      tooltip: {
        style: {
          fontSize: fontSize.value,
        },
        x: {
          formatter: function (value) {
            if (props.xAxisDate) {
              return moment(
                props.isUnixDate
                  ? props.categories[value - 1] * 1000
                  : props.categories[value - 1]
              ).format(props.tooltipXFormat);
            } else {
              return formatNumberIntoHumanizeForm(props.categories[value - 1]);
            }
          },
        },
        y: {
          formatter: function (value) {
            if (value < 0 && props.showNegativeTooltipToPositive) {
              return formatNumberIntoHumanizeForm(-1 * value, props.fraction);
            } else {
              return formatNumberIntoHumanizeForm(value, props.fraction);
            }
          },
        },
      },
      yaxis: {
        title: {
          text: props.showYAxisTitle ? props.yAxisTitle : "",
          style: {
            color: labelColor,
            fontSize: fontSize.value,
            fontWeight: 400,
          },
        },
        labels: {
          minWidth: props.chartYaxisMinWidth,
          maxWidth: 160,
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(value);
          },
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
            fontWeight: 400,
          },
        },
      },
      labels: props.categories,
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 24,
        forceNiceScale: true,
        labels: {
          show: props.showXAxis,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
          },
          formatter: (value) => {
            if (props.xAxisDate) {
              return moment(props.isUnixDate ? value * 1000 : value).format(
                props.dateFormat
              );
            } else {
              return formatNumberIntoHumanizeForm(value);
            }
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    });
    const series = ref(props.chartSeries);
    return { options, series };
  },
};
</script>
<style lang="scss">
.hide-label {
  .apexcharts-yaxis-texts-g {
    text:first-child {
      display: none !important;
    }
  }
}
</style>
<template>
  <div :class="hideFirstLabelValue ? 'hide-label' : ''">
    <apexchart
      :height="chartHeight"
      :type="chartType"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>
