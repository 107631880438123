<template>
  <div>
    <CardToolbar
      v-loading="loading"
      title="Artist And Track Playlists Evolution : Count vs. Reach"
      :show-toolbar-buttons="false"
      margin-class="margin-bottom-12"
      :fontStyle="true"
      :padding="false"
      :shadow-class="true"
      v-if="
        topChartSeries.length && bottomChartSeries.length && categories.length
      "
    >
      <template v-slot:body>
        <div class="top-chart">
          <LineAndAreaChartSingleAxisV2
            :show-x-axis="false"
            :stacked="false"
            :y-axis-title="''"
            :is-unix-date="false"
            :chart-height="350"
            :chart-type="'area'"
            :chart-series="topChartSeries"
            :categories="categories"
            :chart-stroke="{
              curve: 'smooth',
              show: true,
              width: 2,
            }"
            :chart-fill="{
              type: 'gradient',
            }"
            :chart-yaxis-min-width="110"
          />
        </div>
        <div class="bottom-chart">
          <LineAndAreaChartSingleAxisV2
            :hide-first-label-value="true"
            :stacked="false"
            :y-axis-title="''"
            :is-unix-date="false"
            :chart-height="350"
            :chart-type="'area'"
            :chart-series="bottomChartSeries"
            :categories="categories"
            :chart-stroke="{
              curve: 'smooth',
              show: true,
              width: 2,
            }"
            :chart-fill="{
              type: 'gradient',
            }"
            :show-negative-tooltip-to-positive="true"
            :chart-yaxis-min-width="110"
          />
        </div>
      </template>
    </CardToolbar>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { getTrackPlaylistEvolution } from "@/api/mongoWishlists.api";
import CardToolbar from "@/common/components/CardToolbar.vue";
import LineAndAreaChartSingleAxisV2 from "@/common/components/LineAndAreaChartSingleAxisV2.vue";

export default {
  name: "ArtistAndTrackPlaylistEvolution",
  components: { CardToolbar, LineAndAreaChartSingleAxisV2 },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const topChartSeries = ref([]);
    const bottomChartSeries = ref([]);
    const categories = ref([]);

    onMounted(() => {
      getTrackPlaylistEvolutionData();
    });

    const getTrackPlaylistEvolutionData = async () => {
      try {
        loading.value = true;
        const { data } = await getTrackPlaylistEvolution(
          route.params.wishlistID
        );
        topChartSeries.value = data.topSection ? data.topSection : [];
        bottomChartSeries.value = data.bottomSection ? data.bottomSection : [];
        categories.value = data.categories ? data.categories : [];
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    return { loading, topChartSeries, bottomChartSeries, categories };
  },
};
</script>
<style lang="scss" scoped>
.bottom-chart {
  margin-top: -67px;
}
</style>
