<template>
  <div class="wishlist-ui-v2 mb-8" v-loading.fullscreen.lock="loading">
    <WishlistSideBarV2
      v-if="wishlistData.length"
      :related-artists="artists"
      :wishlist-name="wishlistData[0].name"
      :quick-facts="records"
      :genres="
        wishlistData[0].uniqueArtistsGenre
          ? wishlistData[0].uniqueArtistsGenre
          : []
      "
    />
    <div class="wishlist-detail-v2 row" v-if="wishlistData.length">
      <WishlistTable
        :wishlist-fund-slot="
          wishlistData[0].fundSlot ? wishlistData[0].fundSlot : ' '
        "
        :tracks="wishlistTracks"
        v-if="wishlistTracks.length > 0"
        :show-toolbar-buttons="true"
        :title="'Wishlist Tracks'"
        :columns="[
          'Track',
          'Artist',
          'Total Streams',
          'Share of Wishlist Total Streams',
          'Streams of Last Month',
          'Share of Wishlist Streams of last Month',
          'Total Playlists',
          'Projected Revenue From Total Streams',
          'Projected Revenue From Total Streams Last Month',
          'Projected Fanbase From Total Streams',
          'Projected Fanbase From Total Streams Last Month',
          'Release Date',
          'Delete',
        ]"
        :table="'tracks'"
      />
      <div class="wishlist-revenue-table">
        <WishlistTable
          :tracks="wishlistTracks"
          v-if="wishlistTracks.length > 0"
          :show-toolbar-buttons="true"
          :columns="[
            'Track',
            'Artist',
            'Revenue Share From Total',
            'Share of Catalogue From Total',
            'Revenue Share From Last Month',
            'Share of Catalogue From Last Month',
            'Label Share',
          ]"
          :title="'Catalogue Table'"
          :table="'revenue'"
        />
      </div>
      <div class="weighted-listeners">
        <WeightedListeners />
      </div>
      <div class="artist-track-playlist-evolution">
        <ArtistAndTrackPlaylistEvolution />
      </div>
      <div class="weighted-followers">
        <WeightedFollowers />
      </div>
      <div class="weighted-playlists-evolution">
        <WeightedPlaylistsEvolution />
      </div>
      <div class="catalogue">
        <el-row :gutter="12" class="margin-bottom-12">
          <el-col :lg="12" :md="12" :sm="24" :xs="24">
            <kpi-card-v2
              :title="
                formatNumberIntoHumanizeForm(
                  records.weightedWishlistAgeFromTotal
                ) + ' yr'
              "
              :sub-text="'Weighted Wishlist Age From Total'"
            />
          </el-col>
          <el-col :lg="12" :md="12" :sm="24" :xs="24">
            <kpi-card-v2
              :title="
                formatNumberIntoHumanizeForm(
                  records.weightedWishlistAgeFromMonth
                ) + ' yr'
              "
              :sub-text="'Weighted Wishlist Age From Month'"
            />
          </el-col>
        </el-row>
        <div class="margin-bottom-12 d-flex justify-content-end">
          <el-select
            v-model="selectTracksCount"
            class="form-select-solid"
            placeholder="Select Tracks Length"
            @change="getSelectedTracks(selectTracksCount)"
          >
            <el-option :key="1" :value="10" :label="'Top 10 Tracks'"
              >Top 10 Tracks
            </el-option>
            <el-option :key="2" :value="20" :label="'Top 20 Tracks'"
              >Top 20 Tracks
            </el-option>
            <el-option :key="3" :value="'All'" :label="'All Tracks'">
              All Tracks
            </el-option>
          </el-select>
        </div>
        <EmptyCardLoading
          :loading="selectedTracks.length === 0"
          :type="'skeleton'"
          v-if="selectedTracks.length === 0"
          :card-height="'500px'"
          :rows="11"
        />
        <TrackCatalogueV2
          :bottom-margin-class="'margin-bottom-12'"
          v-if="selectedTracks.length > 0"
          :tracks="selectedTracks"
          :margin-left="'-46px'"
        />
      </div>
      <div class="percentage-catalogue">
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          title="Share of Streams: Total vs. Last Month"
          :fontStyle="true"
          :padding="false"
          margin-class="margin-bottom-12"
          :shadow-class="true"
          v-if="
            percentageOfWishlistFromTotalTracksStreamsCategories.length &&
            percentageOfWishlistFromTotalTracksStreamsSeries.length &&
            percentageOfWishlistFromTracksLastMonthStreamsSeries.length &&
            !loading
          "
        >
          <template v-slot:body>
            <div class="row">
              <div class="col-7">
                <NegativeValueChart
                  :chart-height="400"
                  :categories="
                    percentageOfWishlistFromTotalTracksStreamsCategories
                  "
                  :graph-series="[
                    {
                      name: 'Share of Streams Total',

                      color: '#FF4560',
                      data: percentageOfWishlistFromTotalTracksStreamsSeries,
                    },
                  ]"
                  :show-y-axis="true"
                  :negative="
                    Math.min(
                      ...percentageOfWishlistFromTotalTracksStreamsSeries
                    ) > -3
                  "
                />
              </div>
              <div class="col-5" style="margin-left: -46px">
                <NegativeValueChart
                  :chart-height="400"
                  :categories="
                    percentageOfWishlistFromTotalTracksStreamsCategories
                  "
                  :graph-series="[
                    {
                      name: 'Share of Streams last Month',
                      color: '#008FFB',
                      data: percentageOfWishlistFromTracksLastMonthStreamsSeries,
                    },
                  ]"
                  :show-y-axis="false"
                  :negative="false"
                />
              </div>
            </div>
          </template>
        </CardToolbar>
      </div>
      <div class="wishlist-base-forecast">
        <WishlistBaseForecast
          v-if="wishlistData.length"
          :wishlist-revenue="
            wishlistData[0].wishlistRevenue
              ? wishlistData[0].wishlistRevenue
              : 0
          "
          :growthRate="{
            threeYearsGrowth: wishlistData[0].wishlistGrowthRate
              ? wishlistData[0].wishlistGrowthRate
              : 0,
            growthRate: wishlistData[0].wishlistGrowthRate
              ? wishlistData[0].wishlistGrowthRate / 3
              : 0,
            percentage: 80,
          }"
        />
      </div>
      <div class="where-to-listeners">
        <ArtistListenersLocated />
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";
import { getUserWishlists } from "@/api/mongoWishlists.api";
import { ElNotification } from "element-plus";
import CardToolbar from "@/common/components/CardToolbar.vue";
import TrackCatalogueV2 from "@/common/components/TrackCatalogueV2.vue";
import WishlistSideBarV2 from "@/wishlist/components/WishlistSideBarV2.vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import NegativeValueChart from "@/artists/components/BarChartWithNegativeValues";
import WeightedListeners from "@/wishlist/components/WeightedListeners.vue";
import WishlistTable from "@/wishlist/components/WishlistTable.vue";
import WishlistBaseForecast from "@/wishlist/components/WishlistBaseForecast.vue";
import WeightedFollowers from "@/wishlist/components/WeightedFollowers.vue";
import WeightedPlaylistsEvolution from "@/wishlist/components/WeightedPlaylistsEvolution.vue";
import ArtistAndTrackPlaylistEvolution from "@/wishlist/components/ArtistAndTrackPlaylistEvolution.vue";
import ArtistListenersLocated from "@/wishlist/components/ArtistListenersLocated.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";

export default {
  name: "WishlistDetail",
  methods: {
    formatNumberIntoHumanizeForm,
  },
  components: {
    WishlistBaseForecast,
    KpiCardV2,
    WishlistSideBarV2,
    CardToolbar,
    TrackCatalogueV2,
    NegativeValueChart,
    WeightedListeners,
    WishlistTable,
    WeightedFollowers,
    WeightedPlaylistsEvolution,
    ArtistAndTrackPlaylistEvolution,
    ArtistListenersLocated,
    EmptyCardLoading,
  },
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const updateLoading = ref(false);
    const wishlistData = ref([]);
    const wishlistTracks = ref([]);
    const selectTracksCount = ref(10);
    const selectedTracks = ref([]);
    const artists = ref([]);
    const records = ref({
      weightedWishlistAgeFromTotal: 0,
      weightedWishlistAgeFromMonth: 0,
      totalTracks: 0,
      totalStreams: 0,
      totalStreamsLastMonth: 0,
      totalPlaylists: 0,
      totalPlaylistReach: 0,
      createdAt: null,
    });

    const percentageOfWishlistFromTotalTracksStreamsSeries = ref([]);
    const percentageOfWishlistFromTotalTracksStreamsCategories = ref([]);
    const percentageOfWishlistFromTracksLastMonthStreamsSeries = ref([]);
    const percentageOfWishlistFromTracksLastMonthStreamsCategories = ref([]);

    onMounted(() => {
      getWishlist();
    });

    const getWishlist = async () => {
      try {
        loading.value = true;
        const { data } = await getUserWishlists(route.params.wishlistID);
        wishlistData.value = data;
        if (wishlistData.value.length) {
          artists.value = wishlistData.value[0].uniqueArtists;
          records.value.totalTracks = wishlistData.value[0].totalTracks
            ? wishlistData.value[0].totalTracks
            : 0;
          records.value.totalStreams = wishlistData.value[0].totalStreams
            ? wishlistData.value[0].totalStreams
            : 0;
          records.value.totalStreamsLastMonth = wishlistData.value[0]
            .totalStreamsFromMonth
            ? wishlistData.value[0].totalStreamsFromMonth
            : 0;
          records.value.totalPlaylists = wishlistData.value[0].totalPlaylists
            ? wishlistData.value[0].totalPlaylists
            : 0;
          records.value.weightedWishlistAgeFromTotal = wishlistData.value[0]
            .weightedWishlistAgeFromTotal
            ? wishlistData.value[0].weightedWishlistAgeFromTotal
            : 0;
          records.value.weightedWishlistAgeFromMonth = wishlistData.value[0]
            .weightedWishlistAgeFromMonth
            ? wishlistData.value[0].weightedWishlistAgeFromMonth
            : 0;
          records.value.createdAt = wishlistData.value[0].createdAt
            ? wishlistData.value[0].createdAt
            : null;
          wishlistTracks.value = wishlistData.value[0].wishlistTracks.length
            ? wishlistData.value[0].wishlistTracks.sort(
                (a, b) => b.spotifyStreams - a.spotifyStreams
              )
            : [];

          wishlistTracks.value.map((obj) =>
            obj.label_share ? obj.label_share : (obj["label_share"] = 100)
          );
          getSelectedTracks(selectTracksCount.value);
        }
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in getting wishlist data",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const getSelectedTracks = (count) => {
      selectedTracks.value = [];
      percentageOfWishlistFromTotalTracksStreamsSeries.value = [];
      percentageOfWishlistFromTracksLastMonthStreamsSeries.value = [];
      percentageOfWishlistFromTotalTracksStreamsCategories.value = [];
      percentageOfWishlistFromTracksLastMonthStreamsCategories.value = [];
      setTimeout(() => {
        if (typeof count === "number") {
          if (
            wishlistTracks.value &&
            wishlistTracks.value.length &&
            wishlistTracks.value.length > count
          ) {
            selectedTracks.value = wishlistTracks.value.slice(0, count);
          }
        } else {
          selectedTracks.value = wishlistTracks.value;
        }
        calculateBarChartResponse(selectedTracks.value);
      }, 500);
    };

    const getTotalSpotifyStreams = (tracks, key, format = true) => {
      let val = 0;
      if (tracks.length) {
        for (const track of tracks) {
          val += track[key] ? track[key] : 0;
        }
      }
      return format ? formatNumberIntoHumanizeForm(val) : val;
    };

    const calculateBarChartResponse = (tracks) => {
      const sortTracksByPercentageTotalStreams = tracks.sort(
        (a, b) =>
          b.percentageOfWishlistFromTotalStreams -
          a.percentageOfWishlistFromTotalStreams
      );
      for (const value of sortTracksByPercentageTotalStreams) {
        if (
          value.percentageOfWishlistFromTotalStreams &&
          value.percentageOfWishlistFromTotalStreamsLastMonth &&
          value.track
        ) {
          percentageOfWishlistFromTotalTracksStreamsSeries.value.push(
            -1 * value.percentageOfWishlistFromTotalStreams
          );
          percentageOfWishlistFromTracksLastMonthStreamsSeries.value.push(
            value.percentageOfWishlistFromTotalStreamsLastMonth
          );
          percentageOfWishlistFromTotalTracksStreamsCategories.value.push(
            value.track ? value.track : ""
          );
        }
      }
    };

    const calculateStreamsProportion = (streamOfTrack, totalStreams) => {
      if (streamOfTrack) {
        return streamOfTrack / totalStreams;
      } else {
        return 0;
      }
    };

    return {
      loading,
      updateLoading,
      wishlistData,
      wishlistTracks,
      records,
      artists,
      selectedTracks,
      selectTracksCount,
      percentageOfWishlistFromTotalTracksStreamsSeries,
      percentageOfWishlistFromTotalTracksStreamsCategories,
      percentageOfWishlistFromTracksLastMonthStreamsCategories,
      percentageOfWishlistFromTracksLastMonthStreamsSeries,
      getTotalSpotifyStreams,
      calculateStreamsProportion,
      getSelectedTracks,
    };
  },
};
</script>
<style scoped lang="scss">
.wishlist-detail-v2 {
  --bs-gutter-x: 0 !important;
}

.wishlistStatsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
