<template>
  <div class="wishlist-base-forecast mt-2">
    <el-row :gutter="12" class="margin-bottom-12">
      <el-col :lg="12" :md="12" :sm="12" :xs="12">
        <KpiCardV2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              growth,
              'threeYearsGrowth',
              '%'
            )
          "
          :text="'36M Growth'"
          :padding="true"
        />
      </el-col>
      <el-col :lg="12" :md="12" :sm="12" :xs="12">
        <KpiCardV2
          :title="
            checkIsValueAvailableOrNot('object', growth, 'growthRate', '%')
          "
          :text="'36M Avg. YOY Growth'"
          :padding="true"
        />
      </el-col>
    </el-row>
    <el-row :gutter="12" class="margin-bottom-12">
      <el-col :lg="24" :md="24" :sm="24" :xs="24">
        <div class="shadow-card card kpi-card-body">
          <el-row :gutter="12" class="ps-4 pe-4">
            <el-col
              :lg="8"
              :md="8"
              :sm="8"
              :xs="8"
              class="border-right theme-color"
            >
              <p
                class="kpi-card-title truncate pt-2 mb-0 fw-boldest"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="
                  formatNumberIntoHumanizeForm(assumedGrowthRate) + '%'
                "
              >
                {{ formatNumberIntoHumanizeForm(assumedGrowthRate) + "%" }}
              </p>
              <p
                :class="'truncate mb-0 kpi-card-text '"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="'Assumed YOY Growth Rate'"
              >
                Assumed YOY Growth Rate
              </p>
            </el-col>
            <el-col
              :lg="4"
              :md="4"
              :sm="4"
              :xs="4"
              v-for="(revenue, i) in revenues"
              :key="i"
              class="pt-2"
            >
              <KpiCardV2
                :title="formatNumberIntoHumanizeForm(revenue, 0)"
                :text="'Revenues Fy' + (i + 1)"
                :tooltip-text="true"
                :card="false"
              />
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <div class="shadow-card card mt-2 mb-5 mb-xl-10" v-if="loading">
      <EmptyCardLoading
        :loading="loading"
        :type="'skeleton'"
        v-if="loading"
        :card-height="'500px'"
        :rows="11"
      />
    </div>
    <div
      class="base-forecast-chart"
      v-if="
        !loading &&
        forecastValuesConfigured &&
        graphSeries.length &&
        graphCategories.length
      "
    >
      <CardToolbar
        :description="''"
        :title="'Base Forecast'"
        :fontStyle="true"
        :padding="false"
        :shadow-class="true"
        :show-toolbar-buttons="true"
        :margin-class="'margin-bottom-12'"
      >
        <template v-slot:toolbar>
          <a
            class="
              btn btn-sm btn-color-muted btn-active
              active
              btn-active-primary
              px-4
              ms-1
            "
            @click="resetData"
            data-bs-toggle="modal"
            data-bs-target="#wishlist_base_forecast_modal"
            >Configure</a
          >
        </template>
        <template v-slot:body>
          <RevenueChart
            :chart-type="'bar'"
            :series="graphSeries"
            :colors="['#009EF7', '#50CD89', '#F1416C']"
            :currency-values="true"
            :categories="graphCategories"
            :stroke-width="[2, 2, 2]"
            :plot-options="{
              bar: {
                horizontal: false,
                startingShape: 'flat',
                columnWidth: '80%',
              },
            }"
            :stacked="false"
            :chart-class="true"
            :chart-height="400"
          />
        </template>
      </CardToolbar>
    </div>
  </div>
  <ConfigureForecastModal
    @forecast-config="calculateForecastValues"
    :forecast-values-configured="forecastValuesConfigured"
    :forecast-configuration-values="forecastConfigurationValues"
    id="wishlist_base_forecast_modal"
    :scenarios="scenarios"
    :show-exit-evaluation-revenue-card="false"
    :modal-title="'Configure Base Forecast'"
  />
</template>
<script>
import { computed, ref, watch } from "vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import {
  checkIsValueAvailableOrNot,
  chunkArrayIntoEqualSizes,
  formatNumberIntoHumanizeForm,
  sumArrayElements,
} from "@/utils/staticHelper";
import CardToolbar from "@/common/components/CardToolbar.vue";
import RevenueChart from "@/common/components/RevenueChart.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import ConfigureForecastModal from "@/common/components/ConfigureForecastModal.vue";

export default {
  name: "WishlistBaseForecast",
  methods: { formatNumberIntoHumanizeForm, checkIsValueAvailableOrNot },
  components: {
    ConfigureForecastModal,
    RevenueChart,
    CardToolbar,
    KpiCardV2,
    EmptyCardLoading,
  },
  props: {
    wishlistRevenue: {
      type: Number,
      required: true,
    },
    growthRate: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const forecastValuesConfigured = ref(false);
    const forecastConfigurationValues = ref({});
    const graphSeries = ref([]);
    const graphCategories = ref([]);
    const growth = ref(props.growthRate);
    const assumedGrowthRate = ref(0);
    const revenues = ref([]);
    forecastConfigurationValues.value.exitMultiple = {
      title: "Exit Multiple",
      type: "exitMultiple",
      positive: 14,
      medium: 14,
      negative: 14,
      min: 0,
      max: 30,
      step: 1,
      show: false,
    };
    forecastConfigurationValues.value.streamingShare = {
      title: "Growth Rates",
      type: "streamingShare",
      positive: 4,
      medium: 8,
      negative: 4,
      min: 0,
      max: 100,
      step: 1,
      show: true,
    };
    const scenarios = ref(["positive", "medium", "negative"]);
    const entryData = computed(() => {
      return {
        wishlistRevenue: props.wishlistRevenue,
        growthRate: growth.value,
      };
    });

    const assumedGrowth = (val) => {
      assumedGrowthRate.value = val;
    };

    const resetData = () => {
      forecastValuesConfigured.value = false;
      graphSeries.value = [];
      graphCategories.value = [];
      revenues.value = [];
    };

    const calculateForecastValues = ({ scenarios, forecastConfig }) => {
      resetData();
      assumedGrowth(forecastConfig["streamingShare"]["positive"]);
      scenarios.forEach((config) => {
        const currentRevenue = props.wishlistRevenue;
        const growthRateLocal = calculateGrowthRate(
          forecastConfig["streamingShare"][config]
        );
        calculateRevenueNextYearsRevenue(
          currentRevenue,
          growthRateLocal,
          config
        );
      });
      forecastValuesConfigured.value = true;
    };

    const calculateRevenueNextYearsRevenue = (
      revenue,
      growthRate,
      scenario
    ) => {
      const data = [];
      const categories = [];
      let predictedRevenue = revenue;
      let loopCounter = 16;
      for (let i = 0; i < loopCounter; i++) {
        predictedRevenue += Math.trunc((revenue / 4) * (growthRate / 100));
        data.push(predictedRevenue);
        categories.push("Q" + (i + 1));
      }
      graphCategories.value = Array.from(new Set(categories));
      if (scenario === "medium") {
        const dividedArray = chunkArrayIntoEqualSizes(data, 4);
        for (const value of dividedArray) {
          revenues.value.push(sumArrayElements(value));
        }
      }

      graphSeries.value.push({
        name: changeScenarioName(scenario),
        type: "bar",
        data: data,
      });

      loading.value = false;
    };
    const changeScenarioName = (scenario) => {
      if (scenario === "positive") {
        return "Assumed YOY Growth Rate";
      } else if (scenario === "medium") {
        return "Market Growth";
      } else if (scenario === "negative") {
        return "50% of Market Growth";
      }
    };

    const calculateGrowthRate = (config) => {
      return config;
    };

    watch(
      () => entryData.value,
      (value) => {
        loading.value = true;
        if (value.growthRate.growthRate > 0) {
          forecastConfigurationValues.value.streamingShare.positive =
            Number(
              (
                value.growthRate.growthRate *
                (value.growthRate.percentage / 100)
              ).toFixed(1)
            ) > 0
              ? Number(
                  (
                    value.growthRate.growthRate *
                    (value.growthRate.percentage / 100)
                  ).toFixed(1)
                )
              : 1;
        } else {
          forecastConfigurationValues.value.streamingShare.positive =
            Number(
              (
                value.growthRate.growthRate *
                (value.growthRate.percentage / 100)
              ).toFixed(1)
            ) > 0
              ? Number(
                  (
                    value.growthRate.growthRate *
                    (value.growthRate.percentage / 100)
                  ).toFixed(1)
                )
              : 1;
          forecastConfigurationValues.value.streamingShare.min =
            Number(
              (
                value.growthRate.growthRate *
                (value.growthRate.percentage / 100)
              ).toFixed(1)
            ) > 0
              ? Number(
                  (
                    value.growthRate.growthRate *
                    (value.growthRate.percentage / 100)
                  ).toFixed(1)
                )
              : 1;
        }
        if (value.wishlistRevenue) {
          calculateForecastValues({
            scenarios: scenarios.value,
            forecastConfig: forecastConfigurationValues.value,
          });
        }
      },
      { immediate: true, deep: true }
    );
    return {
      loading,
      growth,
      graphSeries,
      graphCategories,
      assumedGrowthRate,
      revenues,
      forecastValuesConfigured,
      forecastConfigurationValues,
      scenarios,
      calculateForecastValues,
      resetData,
    };
  },
};
</script>
