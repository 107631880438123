<template>
  <div class="weighted-listeners-charts">
    <CardToolbar
      v-loading="loading"
      title="Weighted Monthly Listener : Total vs. Last Month"
      :show-toolbar-buttons="true"
      margin-class="margin-bottom-12"
      :fontStyle="true"
      :padding="false"
      :shadow-class="true"
      v-if="
        togglePercentage
          ? listenersFromTotalSeriesPercentage.length &&
            listenersFromLastMonthSeriesPercentages.length &&
            categories.length
          : listenersFromTotalSeries.length &&
            listenersFromLastMonthSeries.length &&
            categories.length
      "
    >
      <template v-slot:toolbar>
        <el-switch
          v-model="togglePercentage"
          @change="getPercentageSeriesValue"
          class="mb-2"
          active-text="Percentage of Listeners"
          inactive-text="Listeners"
        />
      </template>
      <template v-slot:body>
        <div class="top-chart">
          <LineAndAreaChartSingleAxisV2
            v-if="!loading"
            :show-x-axis="false"
            :stacked="true"
            :y-axis-title="''"
            :is-unix-date="false"
            :chart-height="350"
            :chart-type="'area'"
            :chart-yaxis-min-width="100"
            :chart-series="
              togglePercentage
                ? listenersFromTotalSeriesPercentage
                : listenersFromTotalSeries
            "
            :categories="categories"
            :chart-stroke="{
              curve: 'smooth',
              show: true,
              width: 2,
            }"
            :chart-fill="{
              type: 'gradient',
            }"
          />
        </div>
        <div class="bottom-chart">
          <LineAndAreaChartSingleAxisV2
            v-if="!loading"
            :hide-first-label-value="true"
            :stacked="true"
            :y-axis-title="''"
            :is-unix-date="false"
            :chart-height="350"
            :chart-type="'area'"
            :chart-series="
              togglePercentage
                ? listenersFromLastMonthSeriesPercentages
                : listenersFromLastMonthSeries
            "
            :categories="categories"
            :chart-stroke="{
              curve: 'smooth',
              show: true,
              width: 2,
            }"
            :chart-fill="{
              type: 'gradient',
            }"
            :chart-yaxis-min-width="100"
            :show-negative-tooltip-to-positive="true"
          />
        </div>
      </template>
    </CardToolbar>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { getWishlistWeightedListeners } from "@/api/mongoWishlists.api";
import { useRoute } from "vue-router";
import CardToolbar from "@/common/components/CardToolbar.vue";
import LineAndAreaChartSingleAxisV2 from "@/common/components/LineAndAreaChartSingleAxisV2.vue";

export default {
  name: "WeightedListeners",
  components: { CardToolbar, LineAndAreaChartSingleAxisV2 },
  setup() {
    const route = useRoute();
    const listenersFromTotalSeries = ref([]);
    const listenersFromTotalSeriesPercentage = ref([]);
    const listenersFromLastMonthSeries = ref([]);
    const listenersFromLastMonthSeriesPercentages = ref([]);
    const categories = ref([]);
    const loading = ref(false);
    const togglePercentage = ref(false);
    onMounted(() => {
      getWeightedListenersData();
    });

    const getWeightedListenersData = async () => {
      try {
        loading.value = true;
        const { data } = await getWishlistWeightedListeners(
          route.params.wishlistID
        );
        listenersFromTotalSeries.value = data.streamsFromTotalSeries
          ? data.streamsFromTotalSeries
          : [];
        for (const datum of data.streamsFromLastMonthSeries) {
          listenersFromLastMonthSeries.value.push({
            name: datum.name,
            data: datum.data.map((obj) => -1 * obj),
          });
        }
        categories.value = data.categories ? data.categories : [];
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const getPercentageSeriesValue = () => {
      loading.value = true;
      if (togglePercentage.value) {
        listenersFromTotalSeriesPercentage.value = [];
        listenersFromLastMonthSeriesPercentages.value = [];

        let totalSeriesSum = new Array(
          listenersFromTotalSeries.value[0].data.length
        ).fill(0);

        listenersFromTotalSeries.value.forEach((obj) => {
          obj.data.forEach((value, index) => {
            totalSeriesSum[index] += value;
          });
        });

        for (const listener of listenersFromTotalSeries.value) {
          listenersFromTotalSeriesPercentage.value.push({
            name: listener.name,
            data: listener.data.map(
              (val, index) => (val / totalSeriesSum[index]) * 100
            ),
          });
        }

        let totalSeriesSumLastMonth = new Array(
          listenersFromLastMonthSeries.value[0].data.length
        ).fill(0);

        listenersFromLastMonthSeries.value.forEach((obj) => {
          obj.data.forEach((value, index) => {
            totalSeriesSumLastMonth[index] += value;
          });
        });

        for (const listener of listenersFromLastMonthSeries.value) {
          listenersFromLastMonthSeriesPercentages.value.push({
            name: listener.name,
            data: listener.data.map(
              (val, index) =>
                -1 * ((val / totalSeriesSumLastMonth[index]) * 100)
            ),
          });
        }
      }
      setTimeout(() => {
        loading.value = false;
      }, 1000);
    };

    return {
      togglePercentage,
      loading,
      listenersFromTotalSeries,
      listenersFromLastMonthSeries,
      listenersFromTotalSeriesPercentage,
      listenersFromLastMonthSeriesPercentages,
      categories,
      getPercentageSeriesValue,
    };
  },
};
</script>
<style lang="scss" scoped>
.bottom-chart {
  margin-top: -67px;
}
</style>
