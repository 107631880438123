<template>
  <div class="left-sidebar-v2 me-10">
    <div class="card left-sidebar-card-v2 shadow-card">
      <div
        class="mt-1 artist-images"
        style="max-height: 224px; overflow-y: scroll"
      >
        <el-tooltip
          v-for="artist in relatedArtists"
          :key="artist.spotifyID"
          class="box-item"
          effect="dark"
          :content="artist.name"
          placement="top-start"
        >
          <el-avatar
            @click="routeToPage(`ai-artist/${artist.spotifyID}`, true)"
            class="tracksImages cursor-pointer"
            :size="50"
            fill="contain"
            shape="circle"
            :src="
              artist.artistImageURL
                ? artist.artistImageURL
                : '/media/avatars/blank.png'
            "
          />
        </el-tooltip>
      </div>
      <div class="card-body">
        <div class="card-head d-flex flex-column">
          <span
            class="heading-sideBar truncate text-center"
            data-tag="span"
            title=""
            data-tooltip="true"
            :data-text="
              wishlistName
                ? capitalizeEveryWordFirstLetter(wishlistName)
                : 'wishlist name'
            "
            >{{
              wishlistName
                ? capitalizeEveryWordFirstLetter(wishlistName)
                : "wishlist name"
            }}</span
          >
          <hr />
        </div>
        <div class="quick-facts ms-2 mb-8">
          <div class="sub-headings-sidebar d-flex justify-content-between">
            <div>
              <a href="#">
                <img alt="Logo" src="/favicon.ico" class="h-20px" />
              </a>
              <span> Quick Facts</span>
            </div>
            <div class="wishlist-menu-component">
              <el-dropdown trigger="click">
                <button
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                    el-dropdown-link
                  "
                >
                  <i class="bi bi-three-dots fs-3"></i>
                </button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="downloadWishlistData"
                      >Download
                    </el-dropdown-item>
                    <el-dropdown-item @click="updateWishlist"
                      >Update Wishlist
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-8">
              <div class="row">
                <p class="facts-figures">Number of Songs:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Total Streams:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Total Streams Last Month:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Total Playlists:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Total Playlist Reach:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Created At:</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="quickFacts.totalTracks"
                >
                  {{ quickFacts.totalTracks }}
                </span>
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    formatNumberIntoHumanizeForm(quickFacts.totalStreams)
                  "
                >
                  {{ formatNumberIntoHumanizeForm(quickFacts.totalStreams) }}
                </span>
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    formatNumberIntoHumanizeForm(
                      quickFacts.totalStreamsLastMonth
                    )
                  "
                >
                  {{
                    formatNumberIntoHumanizeForm(
                      quickFacts.totalStreamsLastMonth
                    )
                  }}
                </span>
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    formatNumberIntoHumanizeForm(quickFacts.totalPlaylists)
                  "
                >
                  {{ formatNumberIntoHumanizeForm(quickFacts.totalPlaylists) }}
                </span>
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="quickFacts.totalPlaylistReach"
                >
                  {{ quickFacts.totalPlaylistReach }}
                </span>
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="quickFacts.createdAt ? quickFacts.createdAt : ''"
                >
                  {{
                    quickFacts.createdAt
                      ? moment(quickFacts.createdAt).format("MMM DD, YYYY")
                      : ""
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="genre-v2 ms-3">
          <p class="sub-headings-sidebar">Main Genres</p>
          <span
            v-for="(genre, index) in genres"
            :key="index"
            class="badge badge-secondary m-1 active"
            @click="routeToPage('genre-v2?type=main&genre=' + genre, true)"
          >
            {{ genre }}
          </span>
        </div>
        <hr />
        <div class="genre-v2 ms-3">
          <p class="sub-headings-sidebar">Funds</p>
          <span
            v-for="(fund, index) in funds"
            :key="index"
            class="badge badge-secondary m-1 active"
            @click="routeToPage('fund/' + fund._id, true)"
          >
            {{ fund.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  capitalizeEveryWordFirstLetter,
  formatNumberIntoHumanizeForm,
  routeToPage,
} from "@/utils/staticHelper";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import {
  getFundsByWishlistID,
  updateWishlistArtists,
} from "@/api/mongoWishlists.api";
import { ElNotification } from "element-plus";
import moment from "moment";

export default {
  name: "WishlistSideBarV2",
  computed: {
    moment() {
      return moment;
    },
  },
  methods: {
    formatNumberIntoHumanizeForm,
    routeToPage,
    capitalizeEveryWordFirstLetter,
  },
  props: {
    relatedArtists: {
      type: Array,
      required: true,
    },
    genres: {
      type: Array,
      required: true,
    },
    wishlistName: {
      type: String,
      required: true,
    },
    quickFacts: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const funds = ref([]);

    onMounted(() => {
      fetchFundsByWishlistID();
    });

    const downloadWishlistData = () => {
      try {
        window.open(
          `https://forecaster.blingrock.ai/wishlist?wishlist_id=${route.params.wishlistID}`,
          "_blank"
        );
      } catch (e) {
        console.log(e);
      }
    };

    const updateWishlist = () => {
      try {
        updateWishlistArtists({ wishlistID: route.params.wishlistID });
        ElNotification({
          title: "Update Request Sent!",
          message: "This will take some time",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Something went wrong",
          type: "error",
        });
      }
    };

    const fetchFundsByWishlistID = async () => {
      try {
        const { data } = await getFundsByWishlistID(route.params.wishlistID);
        funds.value = data;
      } catch (e) {
        console.log(e);
      }
    };

    return { funds, downloadWishlistData, updateWishlist };
  },
};
</script>
<style scoped>
.tracksImages {
  margin-left: 0.2rem;
}

.left-sidebar-card-v2 {
  max-width: 270px !important;
  width: 270px !important;
}

.el-dropdown-menu__item:hover {
  color: #009ef7 !important;
}
</style>
