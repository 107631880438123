<template>
  <CardToolbar
    v-loading="loading"
    :title="title"
    :show-toolbar-buttons="showToolbarButtons"
    :fontStyle="true"
    :padding="false"
    :shadow-class="true"
    margin-class="margin-bottom-12"
    :margin="true"
  >
    <template v-slot:toolbar>
      <el-switch
        @change="updateFundStatus"
        v-if="table === 'tracks'"
        v-model="fundSlot"
        class="mt-2"
        active-text="Acquired catalogues"
        inactive-text="Equity Catalogues"
        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
      />
      <a
        v-if="table === 'revenue'"
        @click="deleteTrackFromWishlist({}, 'update')"
        class="
          btn btn-sm btn-color-muted btn-active btn-active-primary
          px-4
          active
        "
        >Update</a
      >
    </template>
    <template v-slot:body>
      <el-table
        @sort-change="sortResults"
        :data="tableData"
        :fit="true"
        size="medium"
        tooltip-effect="light"
        stripe
        style="width: 100%"
        header-cell-class-name="table-header-text"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column
          v-if="columns.includes('Track')"
          label="Track"
          prop="track"
          width="350"
          fixed
          align="left"
          sortable="custom"
        >
          <template #default="scope">
            <div
              class="d-flex align-items-center cursor-pointer"
              @click="
                routeToPage(
                  `song/${scope.row.spotifyID}/${scope.row.trackID}`,
                  true
                )
              "
            >
              <el-avatar :size="25" :src="scope.row.imageURL"></el-avatar>
              <span
                class="ms-2 truncate"
                data-tag="span"
                title=""
                data-tooltip="true"
                :data-text="scope.row.track ? scope.row.track : 'N/A'"
              >
                {{ scope.row.track ? scope.row.track : "N/A" }}
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Artist')"
          label="Artist"
          prop="artists"
          width="180"
          align="left"
          sortable="custom"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.artists ? scope.row.artists : 'N/A'"
            >
              {{ scope.row.artists ? scope.row.artists : "N/A" }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Total Streams')"
          label="Total Streams"
          prop="spotifyStreams"
          width="180"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              checkIsValueAvailableOrNot(
                "key",
                null,
                scope.row.spotifyStreams,
                "",
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Share of Wishlist Total Streams')"
          label="Share of Wishlist Total Streams"
          prop="percentageOfWishlistFromTotalStreams"
          width="270"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.percentageOfWishlistFromTotalStreams,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Streams of Last Month')"
          label="Streams of Last Month"
          prop="spotifyStreamsOfLastMonth"
          width="200"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              checkIsValueAvailableOrNot(
                "key",
                null,
                scope.row.spotifyStreamsOfLastMonth,
                "",
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Share of Wishlist Streams of last Month')"
          label="Share of Wishlist Streams of last Month"
          prop="percentageOfWishlistFromTotalStreamsLastMonth"
          width="300"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.percentageOfWishlistFromTotalStreamsLastMonth,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Total Playlists')"
          label="Total Playlists"
          prop="totalPlaylists"
          width="170"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              checkIsValueAvailableOrNot(
                "key",
                null,
                scope.row.totalPlaylists,
                "",
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Projected Revenue From Total Streams')"
          label="Projected Revenue From Total Streams"
          prop="projectedRevenueFromTotalStreams"
          width="300"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.projectedRevenueFromTotalStreams,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="
            columns.includes('Projected Revenue From Total Streams Last Month')
          "
          label="Projected Revenue From Total Streams Last Month"
          prop="projectedRevenueFromTotalStreamsLastMonth"
          width="360"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.projectedRevenueFromTotalStreamsLastMonth,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Projected Fanbase From Total Streams')"
          label="Projected Fanbase From Total Streams"
          prop="projectedFanbaseFromTotalStreams"
          width="300"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.projectedFanbaseFromTotalStreams,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="
            columns.includes('Projected Fanbase From Total Streams Last Month')
          "
          label="Projected Fanbase From Total Streams Last Month"
          prop="projectedFanbaseFromTotalStreamsLastMonth"
          width="360"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.projectedFanbaseFromTotalStreamsLastMonth,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Revenue Share From Total')"
          label="Revenue Share From Total"
          prop="revenueShareFromTotal"
          width="250"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(scope.row.revenueShareFromTotal, 0)
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Share of Catalogue From Total')"
          label="Share of Catalogue From Total"
          prop="shareOfStreamProportionTotal"
          width="250"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.shareOfStreamProportionTotal,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Revenue Share From Last Month')"
          label="Revenue Share From Last Month"
          prop="revenueShareFromMonth"
          width="250"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(scope.row.revenueShareFromMonth, 0)
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Share of Catalogue From Last Month')"
          label="Share of Catalogue From Last Month"
          prop="shareOfStreamProportionMonth"
          width="300"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.shareOfStreamProportionMonth,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns.includes('Release Date')"
          label="Release Date"
          prop="releaseDate"
          width="150"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{ moment(scope.row.releaseDate).format("MMM DD, YYYY") }}
          </template>
        </el-table-column>

        <el-table-column
          label="Label Share"
          width="300"
          align="right"
          v-if="columns.includes('Label Share')"
        >
          <template #default="scope">
            <el-input-number
              v-model="scope.row.label_share"
              size="small"
              :min="0"
              :max="100"
              controls-position="right"
            />
          </template>
        </el-table-column>

        <el-table-column
          label=""
          width="150"
          align="right"
          v-if="columns.includes('Delete')"
        >
          <template #default="scope">
            <el-dropdown trigger="click" placement="top">
              <button
                class="
                  btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  el-dropdown-link
                "
              >
                <i class="bi bi-three-dots fs-3"></i>
              </button>
              <template #dropdown>
                <el-dropdown-menu placement="top">
                  <el-dropdown-item @click="deleteTrackFromWishlist(scope.row)"
                    >Delete Track
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-7 mb-7"
        v-model:currentPage="localPagination.offset"
        v-model:page-size="localPagination.limit"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="localPagination.limit"
        :small="true"
        layout="total, sizes, prev, pager, next"
        :total="localPagination.total"
        @size-change="paginationChanged"
        @current-change="paginationChanged"
      >
      </el-pagination>
    </template>
  </CardToolbar>
</template>
<script>
import { ref, watch } from "vue";
import { paginate, sortArray } from "@/aiBetaBench/helpers";
import {
  checkIsValueAvailableOrNot,
  formatNumberIntoHumanizeForm,
  routeToPage,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import CardToolbar from "@/common/components/CardToolbar.vue";
import {
  removeTracksFromWishlist,
  updateFundSlotByWishlistID,
} from "@/api/mongoWishlists.api";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment/moment";
import { WishlistFundEnum } from "@/common/enums/wishlistFundEnum";

export default {
  name: "WishlistTable",
  computed: {
    WishlistFundEnum() {
      return WishlistFundEnum;
    },
  },
  components: { CardToolbar },
  methods: {
    checkIsValueAvailableOrNot,
    formatNumberIntoHumanizeForm,
    routeToPage,
    tableCellClassName,
    tableRowClassName,
    moment,
  },
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
    showToolbarButtons: {
      type: Boolean,
      default: false,
    },
    wishlistFundSlot: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    table: {
      type: String,
      default: "tracks",
    },
  },
  setup(props) {
    const fundSlot = ref(
      props.wishlistFundSlot === WishlistFundEnum.ACQUIRED_CATALOGUE_LIST
    );
    const loading = ref(false);
    const route = useRoute();
    const store = useStore();
    const tableData = ref([]);
    const tableDataWithoutPagination = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
      sortBy: "spotifyStreams",
      sortOrder: "desc",
    });

    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableDataWithoutPagination.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableDataWithoutPagination.value))
        );
      }
      paginationChanged();
    };

    const paginationChanged = () => {
      localPagination.value.total = tableDataWithoutPagination.value.length;
      tableData.value = paginate(
        tableDataWithoutPagination.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
      loading.value = false;
    };

    const deleteTrackFromWishlist = async (track, type = "delete") => {
      try {
        loading.value = true;
        let successMessage = "Track Removed Successfully";
        if (type === "delete") {
          tableData.value = tableData.value.filter(
            (el) => el.trackID !== track.trackID
          );
          tableDataWithoutPagination.value =
            tableDataWithoutPagination.value.filter(
              (el) => el.trackID !== track.trackID
            );
          localPagination.value.total = tableDataWithoutPagination.value.length;
        } else {
          if (type === "update") {
            successMessage = "Tracks Update Successfully";
          }
        }
        const payload = {
          wishlistID: route.params.wishlistID,
          trackIDs: [track.trackID],
        };
        await removeTracksFromWishlist(payload);
        ElNotification({
          title: "Success!",
          message: successMessage,
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Something Went Wrong",
          type: "error",
        });
      } finally {
        await store.dispatch("WishlistModule/fetchWishlists");
        loading.value = false;
      }
    };

    const updateFundStatus = async () => {
      try {
        const { data } = await updateFundSlotByWishlistID(
          route.params.wishlistID,
          {
            fundSlot:
              fundSlot.value === true
                ? WishlistFundEnum.ACQUIRED_CATALOGUE_LIST
                : WishlistFundEnum.EQUITY_CATALOGUE_LIST,
          }
        );
        fundSlot.value =
          data.fundSlot === WishlistFundEnum.ACQUIRED_CATALOGUE_LIST;
        ElNotification({
          title: "Success",
          message: "Fund Status Updated Successfully",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Something went wrong",
          type: "error",
        });
      }
    };

    watch(
      () => props.tracks,
      (value) => {
        if (value.length > 0) {
          loading.value = true;
          tableDataWithoutPagination.value = value;
          paginationChanged();
        }
      },
      { immediate: true }
    );

    return {
      loading,
      tableData,
      sortBy,
      sortDir,
      localPagination,
      fundSlot,
      updateFundStatus,
      sortResults,
      paginationChanged,
      deleteTrackFromWishlist,
    };
  },
};
</script>
