<template>
  <div>
    <CardToolbar
      description=""
      :title="'Where People Listen To'"
      :show-toolbar-buttons="false"
      margin-class="margin-bottom-12"
      :fontStyle="true"
      :padding="false"
      :shadow-class="true"
    >
      <template v-slot:body>
        <RevenueChart
          v-if="!loading && series.length && categories.length"
          :chart-type="'bar'"
          :chart-height="500"
          :stacked="true"
          :series="series"
          :currency-values="false"
          :color-default="true"
          :categories="categories"
          :stroke-width="[0, 10]"
          :plot-options="{
            bar: {
              horizontal: false,
              columnWidth: '80%',
            },
          }"
          :chart-class="false"
          :show-x-axis-labels="true"
          :hit-events="true"
        />
      </template>
    </CardToolbar>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { getWhereArtistListeners } from "@/api/mongoWishlists.api";
import { useRoute } from "vue-router";
import CardToolbar from "@/common/components/CardToolbar.vue";
import RevenueChart from "@/common/components/RevenueChart.vue";

export default {
  name: "ArtistListenersLocated",
  components: { CardToolbar, RevenueChart },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const series = ref([]);
    const categories = ref([]);
    onMounted(() => {
      getWhereArtistListenersLocated();
    });

    const getWhereArtistListenersLocated = async () => {
      try {
        loading.value = true;
        const { data } = await getWhereArtistListeners(route.params.wishlistID);
        series.value = data.artistsDetail;
        categories.value = data.categories;
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    return { loading, series, categories };
  },
};
</script>
