<template>
  <div class="weighted-followers-chart">
    <CardToolbar
      v-loading="loading"
      title="Weighted Followers"
      :show-toolbar-buttons="false"
      margin-class="margin-bottom-12"
      :fontStyle="true"
      :padding="false"
      :shadow-class="true"
      v-if="series.length && categories.length"
    >
      <template v-slot:body>
        <LineAndAreaChartSingleAxisV2
          :show-x-axis="true"
          :stacked="true"
          :y-axis-title="''"
          :is-unix-date="false"
          :chart-height="400"
          :chart-type="'area'"
          :chart-series="series"
          :categories="categories"
          :chart-stroke="{
            curve: 'smooth',
            show: true,
            width: 2,
          }"
          :chart-fill="{
            type: 'gradient',
          }"
        />
      </template>
    </CardToolbar>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import CardToolbar from "@/common/components/CardToolbar.vue";
import LineAndAreaChartSingleAxisV2 from "@/common/components/LineAndAreaChartSingleAxisV2.vue";
import { getWishlistWeightedFollowers } from "@/api/mongoWishlists.api";

export default {
  name: "WeightedFollowers",
  components: { CardToolbar, LineAndAreaChartSingleAxisV2 },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const series = ref([]);
    const categories = ref([]);
    onMounted(() => {
      getWeightedFollowersData();
    });

    const getWeightedFollowersData = async () => {
      try {
        loading.value = true;
        const { data } = await getWishlistWeightedFollowers(
          route.params.wishlistID
        );
        series.value = data.followersFromTotalProportion
          ? data.followersFromTotalProportion
          : [];
        categories.value = data.categories ? data.categories : [];
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    return { loading, series, categories };
  },
};
</script>
